.email{
    display: flex;
    position: relative;
    justify-content:center;
    align-items: center;
    left:50%;
    top:30%;
    transform:translate(-50%,-50%);
    width:400px;
    height: 350px;
}

.email a{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 80px;
    width:200px;
    height: 200px;
    border:solid 10px;
    border-radius: 200px;
    color:white;
    z-index: 210;
    text-decoration: none;
    transition: 0.3s;
}

.email a:hover{
    color:cyan;
}


      .email a::before {
        content: '';
        border-radius: 100%;
        border: 6px solid white;
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: ring 1.5s infinite;
      }
      .email a::after {
        content: '';
        border-radius: 100%;
        border: 6px solid white;
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: ring 1.5s 1.3s infinite;
      }
      .email a:hover::after, .email a:hover::before {        
        border: 6px solid #DC4492;        
      }

      

@keyframes ring {
    0% {
      width: 30px;
      height: 30px;
      opacity: 1;
    }
    100% {
      width: 300px;
      height: 300px;
      opacity: 0;
    }
}

.contact2{
    display: flex;
}

.contact2 *{
    
    padding:5px;
    font-size: 22px;
    font-weight: 900;
    text-decoration: none;
}

a .bounce-4 {
    animation: bounce-4 1s ease infinite;
}
@keyframes bounce-4 {
    0%   { transform: scale(1,1)    translateY(0); }
    10%  { transform: scale(1.1,.9) translateY(0); }
    30%  { transform: scale(.9,1.1) translateY(-15px); }
    50%  { transform: scale(1,1)    translateY(0); }
    100% { transform: scale(1,1)    translateY(0); }
}