@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  background-color: #010026;
  font-family:  "Montserrat", "sans-serif";
  @apply text-white;
}

img {
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

main {
  display: flex;
}

aside {
  background-color: #c4a8ff;
  width: 18.75rem;
  height: 100vh;
}

.container {
  margin: 4.5rem 1.4rem;
}

.btn-container {
  position: fixed;
}

.container a {
  text-decoration: none;
  font-size: 1.75rem;
  font-weight: 600;
  display: block;
  margin: 20px;
}

button {
  cursor: pointer;
  margin: 1.25rem;
  border: none;
  padding: 0.5rem 1rem;
  background-color: #DC4492;
}

.card{
  transition: all 0.1s linear;
  transform-style: preserve-3d;
}

.back {  
  transform: rotateY(180deg);
}
.back *{
    z-index: 1;
    display: inline-block;
}

.back a{
  color: Crimson;
  transition: 1s;
}
.back a:hover{
  color: red;
}

.front{
  z-index: 2;
  backface-visibility: hidden;
}

.content:hover .card{
  transform: rotateY(180deg);
}

.content{
  perspective: 1000px;
}

.letters{  
  white-space: nowrap;
}

.letters span:hover{
    transition: 2s;
    animation: rubberBand 0.8s ease;    
}

.letters span{
  display: inline-block;
}

.redAnimation{
  animation: redAnimation 3s infinite;  
}

@keyframes rubberBand {
  0% {
    transform: scale3d(1, 1, 1);
  }
  35% {
    transform: scale3d(1.25, .75, 1);
  }
  45% {
    transform: scale3d(.75, 1, 1);
    color: #ea00ff;
  }
  60% {
    transform: scale3d(1.2, .8, 1);
    color: #ea00ff;
  }
  75% {
    transform: scale3d(1.05, .95, 1);
  }
  100%   {
    transform:scale3d(1,1,1);
 }
}

@keyframes redAnimation {
  0% {
    color:crimson;
    text-shadow: 0px 0px #FF0000;
  }
  50% {
    color:red;
    text-shadow: 0px 0px 20px  rgb(255, 0, 255);
  }
  100%   {
    color:crimson;
    text-shadow: 0px 0px #FF0000;
 }
}

/* Scrollbar modification */

 ::-webkit-scrollbar {
    width: 0px;
} 

.bgPurple {
  background-repeat: no-repeat;
  background-image: radial-gradient(#a600b5, #6D0077);
}
